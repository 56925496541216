//image size should be 1200 × 661
//test comment

//email templates
export const leftAlign =
  "https://i.ibb.co/SfkFFWS/emaillayouts-left-Align.webp";
export const leftAlignBig = "https://i.ibb.co/KKtTTRn/emaillayouts-left-Align.webp";
export const salesEmail = "https://i.ibb.co/27DQWCq9/emaillayouts-sales.webp";
export const centered =
  "https://i.ibb.co/zhsyC5r/emaillayouts-centered.webp"; 
export const topBanner = "https://i.ibb.co/R7Jd8ZC/emaillayouts-topbanner.webp";

//vibes emails
export const vibesEmail = "https://i.ibb.co/20STXbPR/vibes-Email-Layouts.webp"; 
export const vibesEmailBig = "https://i.ibb.co/MyfmqXpK/vibes-Email-Layouts.webp";

//infographic experience
export const tvVersion = "https://i.ibb.co/yS0sLgs/tv-goalsetting.webp";
export const tvVersionBig = "https://i.ibb.co/vY1z2Lz/tv-goalsetting.webp"; 

//motion reel
export const reelPreview = "https://i.ibb.co/cgjXRM5/reel-Preview.webp";
export const keysInTheCar = "https://i.ibb.co/XXxNp1t/keys-In-The-Car.webp";
export const mrSquare = "https://i.ibb.co/r2H9cdZ/mrSquare.webp";
export const timeOff = "https://i.ibb.co/6wgswC6/timeOff.webp";
export const goalsTwo = "https://i.ibb.co/fNFTF5t/goalsTwo.webp";

//scrolling infographic
export const previewImg = "https://i.ibb.co/HPbN7N9/preview-Img.webp";

//the Center
export const dogPage = "https://i.ibb.co/3dQWwWS/dog-page.webp";
export const indivPage = "https://i.ibb.co/KxQ6W1X/indiv-page.webp";
export const indivPageBig = "https://i.ibb.co/Mkjn7Xs/indiv-page.webp";

//voispark
export const boxUploader = "https://i.ibb.co/ZzXXnB2/box-Uploader-1.gif";
export const voisparkMain = "https://i.ibb.co/YjqZrYy/voisparkmain.webp";
export const voiSparkMainBig = "https://i.ibb.co/mzM8x1X/voisparkmain.webp";

//mSE
export const addScriptBtn = "https://i.ibb.co/2YFWf2C/add-Script-Btn-1.gif";
export const navigateTabs = "https://i.ibb.co/YBN1f0c/navigate-Tabs-1.gif";
export const preBuiltTool = "https://i.ibb.co/S7D9Dt5/pre-Built-Tool-1.gif";
export const mSEpreview = "https://i.ibb.co/5rSQBsC/m-SEpreview.webp";
export const docking = "https://i.ibb.co/3FNjPyJ/docking-1.gif";

//decision making tool
export const dmInfoIcon = "https://i.ibb.co/VYQCJYBx/dm-infoicon.webp";
export const dmQuestion = "https://i.ibb.co/RGTbL6bJ/dm-question.webp";
export const dmResult = "https://i.ibb.co/7t6KXB9c/dm-result.webp";
export const dmResultDetails = "https://i.ibb.co/hrd7vK9/dm-resultdetails.webp";
export const dmStartPageBig = "https://i.ibb.co/0R9hR4WQ/dm-startpage.webp";

//survey redesign
export const srAudioFeedbackBig = "https://i.ibb.co/N2mQg0tb/sr-Audio-Feedback.webp";
export const srAudioFeedback = "https://i.ibb.co/23sm0zWH/sr-Audio-Feedback.webp";
export const srDatePicker = "https://i.ibb.co/S4d3ZN81/sr-Date-Picker-Desktop.webp";
export const srLinkedSlider = "https://i.ibb.co/23z18Cmq/sr-Linked-Slider-Desktop.webp";
export const srSemantic = "https://i.ibb.co/Dfw0sbkH/sr-Semantic-Differential.webp";

